import React, { useEffect, useState } from 'react';
import { socket } from './socket';
import { useSearchParams } from "react-router-dom";
import { SMQuizContext } from './models/sm_quiz_context';
import Welcome from './Welcome';
import Admin from './Admin';
import Voting from './Voting';
import VoteResults from './VoteResults';
import FinalResults from './FinalResults';

function App() {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [searchParams, setSearchParams] = useSearchParams();
  const [context, setContext] = useState<SMQuizContext | undefined>(undefined);
  const [quizState, setQuizState] = useState<string | undefined>(undefined);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      const teamId = searchParams.get("teamId")
      socket.emit("hello", teamId || undefined);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('sendContextWithState', (context, state) => {
      setContext(context);
      setQuizState(state);
      console.log(context)
    });

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, []);

  const teamId = searchParams.get("teamId")

  const alreadyVote = context?.votes.filter(vote => vote.QuestionIdx === context.currentQuestionIdx && vote.TeamId === teamId);

  return (
    <div className="App">
      {searchParams.get("admin") === 'anettestamas' ?
        <Admin
          currentTeams={context?.teams || []}
          reset={() => {
            socket.emit('reset');
          }}
          nextQuestion={() => {
            socket.emit('nextQuestion');
          }}
          previousQuestion={() => {
            socket.emit('previousQuestion');
          }}
          showVoteResults={() => {
            socket.emit('showVoteResults');
          }}
        /> : null}
      {
        quizState === 'preWelcome' ?
          <div style={{ textAlign: 'center' }}><h1>Aki kíváncsi, hamar megöregszik...</h1><img src="look.gif" /></div>
          :
          quizState === 'welcome' ?
            <Welcome changeNickname={(nickname) => {
              socket.emit('setNickname', nickname);
            }} currentTeams={context?.teams || []} /> :
            quizState === 'fetchingVotes' ?
              <Voting questions={context?.questions || []}
                votes={context?.votes || []}
                teams={context?.teams || []}
                currentQuestionIdx={context?.currentQuestionIdx || 0}
                vote={(answerIdx) => {
                  if (context?.currentQuestionIdx !== undefined) {
                    socket.emit('vote', context?.currentQuestionIdx, answerIdx);
                  }
                }}
                currentVoteIdx={
                  alreadyVote !== undefined && alreadyVote?.length > 0 ? alreadyVote[0].VoteNumber : null
                }
              />
              : quizState === 'showingVoteResults' ?
                <VoteResults votes={context?.votes || []} currentQuestionIdx={context?.currentQuestionIdx || 0} teams={context?.teams || []} questions={context?.questions || []} />
                :
                quizState === 'showingFinalResults' ?
                  <FinalResults votes={context?.votes || []} teams={context?.teams || []} questions={context?.questions || []} />
                  : null
      }
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { Team } from './models/team';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

function Welcome(props: {
  currentTeams: Team[],
  changeNickname: (nickname: string) => void
}) {
  const [nickname, setNickname] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div style={{
      margin: "30px",
      opacity: "80%"
    }}>
      <Paper elevation={3}>
        { searchParams.get("teamId") !== null ?
      <div style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
        <TextField
          style={{ flex: 1 }}
          id="outlined-basic"
          label="Csapatnév"
          variant="outlined"
          value={nickname}
          onChange={(e) => { if (e.target.value.length < 40) setNickname(e.target.value) }}
        />
        <Button variant="contained" onClick={() => { props.changeNickname(nickname) }}>Mentés</Button>
      </div>
      :
      <Paper elevation={3} style={{padding: '10px', marginBottom: '20px', textAlign: 'center', color: "red"}}><h3>Olvassátok be a QR kódot az asztalon és adjatok nevet a csapatotoknak!</h3></Paper>
      }
      </Paper>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{width: '30%'}}><strong>Asztal</strong></TableCell>
              <TableCell><strong>Csapatnév</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.currentTeams.map(team =>
              <TableRow
                key={team.Id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{team.Fixedname}</TableCell>
                <TableCell><strong>{team.Nickname}</strong></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Welcome;

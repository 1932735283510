import { Question } from './models/question';
import { Vote } from './models/vote';
import { Team } from './models/team';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';


interface TeamPoint {
  teamId: string;
  point: number;
  voteTimeMs: number;
}

function FinalResults(props: {
  votes: Vote[],
  teams: Team[],
  questions: Question[]
}) {

  /* calculate final result */
  let finalPoints: TeamPoint[] = new Array();

  for (let team of props.teams) {
    finalPoints.push({ teamId: team.Id, point: 0, voteTimeMs: 0 });
  }


  for (let questionIdx = 0; questionIdx < props.questions.length; questionIdx++) {
    /* give points to teams */
    for (const team of props.teams) {
      const resultIdx = finalPoints.findIndex(result => result.teamId === team.Id);
      for (const vote of props.votes.filter(vote => vote.QuestionIdx === questionIdx)) {
        if (vote.TeamId === team.Id) {
          finalPoints[resultIdx].point += vote.VoteNumber === props.questions[questionIdx].CorrectAnswerIndex ? 1 : 0;
          finalPoints[resultIdx].voteTimeMs += vote.VoteTimeMs;
        }
      }
      if (props.votes.find(vote => vote.QuestionIdx === questionIdx && vote.TeamId === team.Id) === undefined) {
        finalPoints[resultIdx].point -= 1;
      }
    }
  }

  finalPoints = finalPoints.sort((a, b) => b.point === a.point ? a.voteTimeMs - b.voteTimeMs : b.point - a.point);

  return (
    <Paper elevation={3} style={{ margin: '20px', opacity: "80%" }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Helyezés</strong></TableCell>
              <TableCell align="center"><strong>Csapatnév</strong></TableCell>
              <TableCell align="center"><strong>Pontok száma</strong></TableCell>
              <TableCell align="center"><strong>Válaszidő</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {finalPoints.map((result, index) =>
              <TableRow
                key={index}
              >
                <TableCell component="th" scope="row">
                  {index + 1}. helyezett
                </TableCell>
                <TableCell align="center">{props.teams.find(team => team.Id === result.teamId)?.Fixedname} - {props.teams.find(team => team.Id === result.teamId)?.Nickname}</TableCell>
                <TableCell align="center">{result.point}</TableCell>
                <TableCell align="center">{(result.voteTimeMs / 1000.0).toFixed(2)} másodperc</TableCell>
              </TableRow>)}
          </TableBody>
        </Table>

      </TableContainer>
    </Paper>
  );
}

export default FinalResults;

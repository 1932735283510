import { io, Socket } from "socket.io-client";
import { SMQuizContext } from "./models/sm_quiz_context";

interface ServerToClientEvents {
    sendContextWithState: (context: SMQuizContext, state: string) => void;
}

interface ClientToServerEvents {
    hello: (teamId?: string) => void;
    setNickname: (nickname: string) => void;
    reset: () => void;
    nextQuestion: () => void;
    previousQuestion: () => void;
    showVoteResults: () => void;
    vote: (questionIdx: number, answerIdx: number) => void;
}

// please note that the types are reversed
export const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io()
import React, { useEffect, useState } from 'react';
import { Chip, Divider, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup } from '@mui/material';
import { Question } from './models/question';
import { Vote } from './models/vote';
import { Team } from './models/team';

function VoteResults(props: {
  votes: Vote[],
  teams: Team[],
  questions: Question[],
  currentQuestionIdx: number
}) {

  return (
    <>
      <Paper style={{ margin: "20px", padding: "10px", textAlign: "center", opacity: "80%" }} elevation={3}>
        <h3>
          {props.questions[props.currentQuestionIdx].Text}<br />
          <span style={{color: "green"}}>{props.questions[props.currentQuestionIdx].Answers[props.questions[props.currentQuestionIdx].CorrectAnswerIndex]}</span>
        </h3>
      </Paper>
      <Paper style={{ margin: "20px", padding: "10px", opacity: "80%" }} elevation={3}>
        <div>


          {
            props.teams.map(team =>
              <>
                <div style={props.votes.filter(vote =>
                  vote.QuestionIdx === props.currentQuestionIdx &&
                  vote.TeamId == team.Id &&
                  vote.VoteNumber === props.questions[props.currentQuestionIdx].CorrectAnswerIndex).length ?
                  { backgroundColor: '#1aa419', color: 'white', padding: '5px', marginBottom: '10px' }
                  :
                  { backgroundColor: '#f24a59', color: 'white', padding: '5px', marginBottom: '10px' }} >
                  <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                      {team.Fixedname + " - " + team.Nickname}<br />
                      <strong>Válasz: </strong>
                      {props.votes.filter(vote => vote.QuestionIdx == props.currentQuestionIdx && vote.TeamId == team.Id).length ?
                        <>
                          {props.questions[props.currentQuestionIdx].Answers[props.votes.filter(vote => vote.QuestionIdx == props.currentQuestionIdx && vote.TeamId == team.Id)[0].VoteNumber]}
                        </> : ' - '
                      }
                    </div>
                    <div style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>
                      {props.votes.filter(vote => vote.QuestionIdx == props.currentQuestionIdx && vote.TeamId == team.Id).length ?
                        <>
                          {(props.votes.filter(vote => vote.QuestionIdx == props.currentQuestionIdx && vote.TeamId == team.Id)[0].VoteTimeMs / 1000.0).toFixed(2)}  másodperc
                        </> : null
                      }

                    </div>
                  </div>
                </div>
                <Divider />
              </>
            )
          }
        </div>
      </Paper>
    </>
  );
}

export default VoteResults;

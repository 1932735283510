import { Chip, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup } from '@mui/material';
import { Question } from './models/question';
import { useSearchParams } from 'react-router-dom';
import { Team } from './models/team';
import { Vote } from './models/vote';

function Voting(props: {
  questions: Question[],
  currentQuestionIdx: number,
  vote: (answerIdx: number) => void,
  currentVoteIdx: number | null,
  teams: Team[],
  votes: Vote[]
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const projector = searchParams.get("teamId") === null;
  return (
    <>
      <Paper style={{ margin: "20px", padding: "10px", textAlign: "center", opacity: "80%" }} elevation={3}>
        <h3>{props.questions[props.currentQuestionIdx].Text}</h3>
      </Paper>
      <Paper style={{ margin: "20px", padding: "10px" }} elevation={3}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={props.currentVoteIdx}
            onChange={(e) => {
              if (!projector) props.vote(parseInt(e.target.value));
            }}
          >
            {props.questions[props.currentQuestionIdx].Answers.map((answer, idx) =>
              <FormControlLabel value={idx} key={idx} control={<Radio />} label={answer} />
            )}
          </RadioGroup>
        </FormControl>
      </Paper>
      {projector ?
        <Paper style={{ margin: "20px", padding: "10px", opacity: "80%" }} elevation={3}>

          <h4>Még nem szavaztak:</h4>
          {props.teams.filter(team => props.votes.filter(vote => vote.TeamId === team.Id && vote.QuestionIdx === props.currentQuestionIdx).length === 0).map(team =>
            <Chip label={team.Fixedname + " - " + team.Nickname} key={team.Id} variant="outlined" style={{margin: '5px', fontSize: '1.2rem'}} />)}

        </Paper>
        : null}
    </>
  );
}

export default Voting;

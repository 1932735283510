import React, { useEffect, useState } from 'react';
import { Team } from './models/team';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function Admin(props: {
  currentTeams: Team[],
  reset: () => void,
  nextQuestion: () => void,
  previousQuestion: () => void,
  showVoteResults: () => void
}) {
  const [resetConfirmationShown, setResetConfirmationShown] = useState<boolean>(false);
  return (
    <>
      <Dialog
        open={resetConfirmationShown}
        onClose={()=>{setResetConfirmationShown(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Tuti resetelni akarod?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ha reseteled eltűnik minden nickname és szavazat.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setResetConfirmationShown(false)}}>Vissza</Button>
          <Button onClick={()=>{props.reset(); setResetConfirmationShown(false)}} autoFocus>
            RESET
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ margin: "20px" }}>
        <Button style={{ backgroundColor: "red" }} variant="contained" onClick={() => { setResetConfirmationShown(true); }}>RESET</Button><br /><br />
        <Button variant="contained" onClick={() => { props.nextQuestion(); }}>Következő kérdés</Button><br /><br />
        <Button variant="contained" onClick={() => { props.previousQuestion(); }}>Előző kérdés</Button><br /><br />
        <Button variant="contained" onClick={() => { props.showVoteResults(); }}>Válaszok mutatása</Button><br /><br />
      </div>
    </>

  );
}

export default Admin;
